button.whats {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 1.5vw;
  z-index: 15;
  width: 100px;
  height: 55px;
  right: -3vw;
  transition: ease 0.4s;
  border-radius: 105px;
  background-color: var(--primary-color);
  border: none;
}

button.whats a{
  display: flex;
  justify-content: center;
  align-items: center;
}
button.whats img{
  height: 45px;
  margin-right: 44px;
  margin-left: 10px;
  margin-top: 2px;
}
button.whats:hover{
  cursor: pointer;
}
button.whats.open{
  width: 220px;
  right: -3vw;
  background-color: var(--primary-color);
  border-radius: 40px;
}
button.whats.open img{
  margin-right: 1px;

}
.whats.open  p{
  color: #fff;
  font-size: 16px;
  margin-bottom: 0.5vw;
  text-align: center;
  margin-right: 40px;
  transition: ease 0.8s;
}
.whats.open .text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}

@media(max-width: 640px){
  button.whats{
    background: none;
    width: 50px;
    margin-right: 10px;
    height: 60px;
    bottom: 12vw;
  }
  button.whats.open{
    background: none;
    width: 50px;
    margin-right: 15px;
  }
  button.whats.open p{
    display: none;
  }
  button.whats img{
    height: 35px;
  }
  #whats img{
    height: 8vw;
  }
  #whats{
    bottom: 5vw;
  }
}
