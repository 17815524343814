:root {
  --primary-color: #16947B;
  --cor-escura: #0B5F37;
  --title-color: #322153;
  --text-color: #fff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  min-height: 100%;
  height: auto;
}
#root{
  min-height: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
body {
  background: #fff;

  -webkit-font-smoothing: antialiased;
  color: var(--text-color);
  min-height: 100%;
  height: auto;
}

body, input, button, div, select{
  font-family: salesforce-sans, "Helvetica Neue", helvetica, arial, sans-serif;
  outline: none;
}
p{
  color: var(--primary-color);
}

a{
  text-decoration: none;
  color: #fff;
  text-align: center;
}


.btn-padrao{
  height: 35px;
  background-color: var(--primary-color);
  border: none;
  font-size: 16px;
  border-radius: 5px;
  color: #fff;
  transition-duration: 0.2s;
}
.btn-padrao:hover{
  background-color: rgb(17, 119, 99);
  cursor: pointer;
}
p.msg-error{
  color: rgb(196, 23, 23);
  width: 25vw;
  text-align: center;
  margin-top: 0.5vw;
  font-size: 1.2vw;
  height: 30px;
  margin-bottom: 0;
}
p.error{
  color: rgb(196, 23, 23);
}

.container-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input.error{
  border: 2px solid rgb(196, 23, 23);
}

button[type=submit]{
  margin-top: 20px;
  height: auto;
  width: 20%;
  padding: 5px 0;
}

.container-input.error input {
  border: 2px solid rgb(221, 52, 52);
}

.container-input.error select {
  border: 2px solid rgb(221, 52, 52);
}

.container-input.error p{
  color: rgb(233, 50, 50);
}

@media(max-width: 640px){
  .btn-padrao{
    font-size: 3vw;
  }
  p.msg-error{
    width: 60%;
    font-size: 3.5vw;
  }
  button[type=submit]{
    width: 40%;
    font-size: 3.5vw;
  }
}

