@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;669;700;800;900&display=swap);
#page-title{
  position: absolute;
  width: 30%;
  top: 15vw;
  right: 18vw;
  color: var(--cor-escura);
  margin-top: 2vw;
  font-size: 2vw;
  text-align: center;
}
#logo{
  position: absolute;
  height: 25vw;
  width: 39vw;
  top: 6.5vw;
  left: 8vw;
}

#imagem {
  height: 20vw;
  width: 35%;
  border-radius: 8px;
  box-shadow: 5px 8px 10px #333;
  position: absolute;
  left: 10vw;
  top: 8vw
}

@media(max-width: 640px){
  #logo{
    display: none;
  }
  #imagem{
    display: none;
  }
  #page-title{
    font-size: 4vw;
    width: 100%;
    left: 0vw;
    text-align: center;
  }

}

.evite {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.evite .container-column{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin-top: 0vw;
}

.evite p{
  text-align: center;
  color: var(--cor-escura);
  width: 40vw;
  font-size: 1.8vw;
  font-weight: bold;
  margin-top: 0;
}

.evite p strong{
  font-size: 2vw;
  color: #0F6856;
}
.evite button[type=button]{
  margin: 0;
  margin-top: 1vw;
  border-radius: 2px;
}
.animacao{
  margin-top: 4vw;
}

.bandeiras {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5vw;
  margin-left: 1.2vw;
}
.bandeiras .bandeira{
  height: 2vw;
  margin-right: 1.2vw;
}

@media(max-width: 640px){
  .evite{
    margin-left: 0%;
    width: 100%;
    margin-bottom: 5vw;
  }
  .evite p{
    width: 125%;
    font-size: 3.7vw;
  }
  .evite p strong{
    font-size: 4vw;
  }
  .evite button{
    width: 40%;
    height: 6vw;
    font-size: 3vw;
    margin-left: 0;
  }
  .container-column .animacao{
    max-width: 180%;
    margin: 0;
    overflow: hidden;
  }
  .bandeiras{
    margin-left: 2vw;
    margin-top: 2vw;
  }
  .bandeiras .bandeira{
    height: 4vw;
    margin-right: 2vw;
  }
}

button#simulacao{
  margin-top: 2vw;
  width: 60%;
}

.centro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  margin-top: 8vw;
}
.centro .texto-parcelamos {
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centro .texto-parcelamos h2 {
  font-size: 2vw;
  color: var(--cor-escura);
}
.centro .texto-parcelamos ul {
  margin-top: 10px;
}
.centro .texto-parcelamos ul li{
  list-style: none;
  text-align: center;
  padding: 0.1vh;
  font-size: 1.8vw;
  font-weight: bold;
}
.centro img{
  height: 25vw;
  width: 30vw;
  border-radius: 10px;
  box-shadow: 5px 8px 10px #333;
}

@media(max-width: 640px){
  button#simulacao{
    margin-top: 3vw;
  }
  .centro{
    flex-direction: column-reverse;
  }
  .centro .texto-parcelamos h2{
    font-size: 3.7vw;
  }
  .centro .texto-parcelamos ul{
    margin-top: 0;
  }
  .centro .texto-parcelamos ul li{
    font-size: 3.5vw;
  }
  .centro #money{
    height: 30vw;
    width: 45vw;
    margin-bottom: 2vw;
    box-shadow: none;
  }
}

.pag-debito{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8vw;
}

.pag-debito p{
  text-align: center;
}

.pag-debito img{
  height: 25vw;
  width: 30vw;
  border-radius: 1vw;
  box-shadow: 5px 8px 10px #333;
}

.pag-debito .text{
  width: 40%;
  margin-left: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pag-debito .text h1{
  color: var(--cor-escura);
  text-align: center;
  font-size: 2vw;
}

.pag-debito .text p{
  text-align: center;
  width: 70%;
  font-size: 2vw;
  margin-top: 1vw;
  font-weight: bold;
}

.pag-debito .text button{
  margin-top: 2vw;
}

@media(max-width: 640px){
  .pag-debito{
    flex-direction: column;
    margin-top: 8vw;
  }
  .pag-debito img {
    margin-bottom: 3vw;
    box-shadow: none;
    height: 30vw;
    width: 45vw;
  }

  .pag-debito .text{
    width: 90%;
    margin-left: 0;
  }
  .pag-debito .text h1{
    font-size: 3.7vw;
  }
  .pag-debito .text p{
    font-size: 3.3vw;
  }
}

.parceria {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 80%;
  height: 25vw;
}
.parceria div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
  margin-left: 5vw;
}
.parceria div p{
  text-align: center;
  max-width: 320px;
  margin: 0;
  font-size: 2.5vw;
  color: var(--cor-escura);

}
.parceria div button {
  margin-top: 15px;
}
.parceria div button a{
  text-decoration: none;
  font-size: 1.6vw;
  color: #fff;
}
.parceria img{
  height: 20vw;
  border-radius: 1vw;
  box-shadow: 5px 8px 10px #333;
}

@media(max-width: 640px){
  .parceria{
    flex-direction: column-reverse;
    height: 50vw;
  }
  .parceria div{
    margin-left: 0;
  }
  .parceria div p{
    font-size: 4vw;
  }
  .parceria img{
    height: 27vw;
    margin-bottom: 3vw;
    box-shadow: none;
  }
  .parceria div button{
    width: 100%;
    height: 6vw;
    font-size: 3vw;
    margin-left: 0;
  }
  .parceria div button a{
    font-size: 3vw;
    margin-left: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5vw;
}

.container button {
  width: 35%;
  margin-top: 5vw;
}

@media(max-width: 640px){
  .container button{
    width: 40%;
    height: 6vw;
    font-size: 3vw;
    margin-left: 0;
  }

}

.quem-somos{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 3vw;
}
.quem-somos .title{
  width: 30vw;
  margin: 0;
  margin-left: 40%;
  text-align: center;
}
.quem-somos .title #title{
  font-size: 2vw;
  font-weight: bold;
  color: var(--cor-escura);
}
.quem-somos .title #subtitle{
  font-size: 1.8vw;
  margin-top: 15px
}
.quem-somos .centro{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 8vw;
  width: 80%;
}
.quem-somos .centro p {
  font-size: 2vw;
  width: 100%;
  max-width: 950px;
  text-align: center;
  margin-top: 3vw;
  font-weight: 600;
}
.quem-somos div img{
  height: 25vw;
}
.quem-somos div button{
  width: 35%;
  height: 2.4vw;
  margin-top: 85px;
}
.quem-somos div button a{
  font-size: 1vw;
  color: #fff

}

@media(max-width: 640px){
  .quem-somos  .title{
    margin-left: 0;
    margin-top: 5vw;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .quem-somos .title #title{
    font-size: 5vw;
    width: 100%;
  }
  .quem-somos .title #subtitle{
    font-size: 4.5vw;
    width: 60%;
  }
  .quem-somos .centro{
    margin-top: 10vw;
  }
  .quem-somos .centro img{
    height: 35vw;
  }
  .quem-somos .centro p{
    font-size: 3.7vw;
    width: 110%;
    font-weight: 600;
  }
  .quem-somos .centro button{
    width: 80%;
    height: 5vw;
    margin-top: 30px;
    height: 6vw;
    border-radius: 5px;
  }
  .quem-somos .centro button a{
    font-size: 3vw;
  }
}

.como-funciona{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5vw;
  align-items: center;
}

.como-funciona .explicacao{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8vw;
  width: 60%;
}
.como-funciona .explicacao h4{
  font-size: 1.8vw;
  text-align: center;
  width: 100%;
  color: var(--cor-escura)
}
.como-funciona .explicacao p{
  text-align: center;
  margin-top: 2.3vw;
  font-size: 1.6vw;
  font-weight: bold;
}

.como-funciona .explicacao p a{
  color: var(--primary-color);
  font-weight: bold;
}
.como-funciona .explicacao p a:hover{
  color: var(--cor-escura)
}

.como-funciona button{
  width: 20%;
  margin-top: 2vw;
}

@media(max-width: 640px){
  .como-funciona #title{
    margin-left: 0;
    font-size: 4vw;
  }
  .como-funciona .explicacao{
    width: 95%;
  }
  .como-funciona .explicacao h4{
    font-size: 3.7vw;
  }
  .como-funciona .explicacao p {
    font-size: 3.7vw;
    margin-top: 5vw;
    width: 95%;
  }
  .como-funciona button{
    width: 50%;
    height: 6vw;
    font-size: 3.5vw;
    margin-top: 4vw;
  }
}

.form-parcelado{
  display: flex;
  flex-direction: column;
  align-items: center;
}



.dados{
  margin-top: 15vw;
  width: 100%;
}

.dados.endereco {
  margin-top: 0;
}

.dados input{
  border: none;
}
.dados h3{
  color: var(--cor-escura);
  font-size: 1.8vw;
  text-align: start;
  width: 50%;
}

.dados .row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dados .form{
  width: 50%;
  background: var(--primary-color);
  padding: 1vw;
  border-radius: 5px;
  margin-top: 0.5vw;
}

.dados .form .container-input{
  width: 80%;
  max-width: 450px;
}
.dados .form .container-input p{
  color: #f0f0f0;
  font-weight: bold;
  font-size: 18px;
}
.dados .form .container-input input, select{
  height: 35px;
  color: var(--cor-escura);
  font-size: 18px;
  padding-left: 10px;
  width: 90%;
  margin-bottom: 1vw;
  border-radius: 5px;
}

.dados .form .container-input.error input{
  border: 2px solid rgb(221, 52, 52);
}

.dados .form .container-input.error select{
  border: 2px solid rgb(221, 52, 52);
}

.dados .form .container-input.error p{
  color: rgb(233, 50, 50);
}


@media(max-width: 640px){
  .dados .form{
    width: 95%;
  }
  .dados h3{
    font-size: 4vw;
    width: 95%;
  }

  .dados.endereco {
    margin-top: -20px;
  }

  .dados .form{
    padding: 3vw;
  }
  .dados .form .container-input{
    width: 90%;
  }
  .dados .form .container-input p{
    font-size: 12px;
  }
  .dados .form .container-input input, select{
    height: 25px;
    font-size: 12px;
  }
}

.simulador {
  width: 100%;
  margin-top: 35px;
}
.simulador h3{
  color: var(--cor-escura);
  font-size: 24px;
  width: 50%;
  text-align: start;
}
.form-simulador{
  width: 50%;
  background: var(--primary-color);
  padding: 10px;
  border-radius: 5px;
  margin-top: 0.5vw;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 22px;
}
.form-simulador .container-input{
  width: 80%;
}
.form-simulador .container-input p{
  color: #f0f0f0;
  font-weight: bold;
  font-size: 18px;
}
.form-simulador .container-input input{
  height: 35px;
  border-radius: 5px;
  color: var(--cor-escura);
  font-size: 16px;
  padding-left: 10px;
  width: 60%;
  margin-bottom: 1vw;
  border: none;
}

.form-simulador .container-input .slider{
  width: 530px;
  height: 15px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  margin-top: 1vw;
  align-items: center;
  border-radius: 5px;
}

.form-simulador .container-input .thumb{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--primary-color);
  border: 0.3px solid #FFF;
}

.form-simulador .container-input .num-vezes{
  display: flex;
  justify-content: space-between;
  width: 530px;
  margin-top: 0.5vw;
}

.form-simulador .resultado{
  display: flex;
  width: 80%;
  margin-top: 0;
  justify-content: flex-start;
  align-items: center;
  /* height: 5vw; */
  margin-top: 15px;
}

.form-simulador .resultado .vezes{
  font-size: 22px;
  color: #f0f0f0;
  margin: 0;
  margin-right: 5px;
  align-self: center;
}

.form-simulador .resultado .parcela{
  font-size: 25px;
  margin: 0;
  color: #f0f0f0;
  align-self: center;
}

@media(max-width: 640px){
  .simulador h3{
    font-size: 4vw;
    margin-top: -35px;
    width: 95%;
  }
  .form-simulador{
    width: 95%;
    padding: 10px;
  }

  .form-simulador .container-input{
    width: 90%;
    margin-bottom: 10px;
  }
  .form-simulador .container-input p{
    font-size: 3.3vw;
  }
  .form-simulador .container-input input{
    height: 6vw;
    font-size: 3.3vw;
  }
  .form-simulador .container-input .slider{
    height: 10px;
    width: 180px;
  }

  .form-simulador .container-input .thumb{
    height: 25px;
    width: 25px;
  }
  .form-simulador .container-input .num-vezes{
    margin-top: 2vw;
    width: 180px;
  }

  .form-simulador .resultado{
    display: flex;
    width: 80%;
    margin-top: 0;
    /* justify-content: center;
    align-items: center; */
    height: 5vw;
  }

  .form-simulador .resultado .vezes{
    font-size: 12px;
    color: #f0f0f0;
    margin: 0;
    margin-right: 5px;
    align-self: center;
  }

  .form-simulador .resultado .parcela{
    font-size: 15px;
    margin: 0;
    color: #f0f0f0;
    align-self: center;
  }
}

.arquivos{
  width: 100%;
  margin-top: 2vw;
}

.arquivos h3{
  font-size: 24px;
  color: var(--cor-escura);
  width: 50%;
  text-align: start;
}

.arquivos .form-arquivos{
  width: 50%;
  background-color: var(--primary-color);
  padding: 10px;
  border-radius: 5px;
  margin-top: 0.5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.arquivos .form-arquivos input[type=file]{
  display: none;
}
.arquivos .form-arquivos #choose-file{
  border: none;
  outline: none;
  background: #f0f0f0;
  height: auto;
  min-height: 2vw;
  border-radius: 3px;
  color: var(--cor-escura);
  padding: 5px;
  margin-top: 1vw;
  margin-bottom: 1.5vw;
}
.arquivos .form-arquivos #choose-file:hover{
  background: #fff;
  cursor: pointer;
}

.arquivos .form-arquivos .files{
  margin-top: 1vw;
  width: 100%;
}

.arquivos .form-arquivos .files .file{
  background: #f0f0f0;
  border-radius: 3px;
  margin-bottom: 0.2vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin: 3px;
  padding: 4px;
}
.arquivos .form-arquivos .files .file p {
  color: var(--primary-color);
  width: 100%;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.arquivos .form-arquivos .files .file button{
  border: none;
  background: none;
}
.arquivos .form-arquivos .files .file img{
  height: 1.2vw;
  margin: 2px 3px;
  transition-duration: 0.1s;
}
.arquivos .form-arquivos .files .file img:hover{
  cursor: pointer;
  /* height: 1.2vw; */
}

@media(max-width: 640px){
  .arquivos{
    margin-top: 8vw;
  }
    .arquivos h3{
    font-size: 4vw;
    width: 95%;
  }

  .arquivos .form-arquivos{
    width: 95%;
    padding: 3vw;
  }

  .arquivos .form-arquivos .files{
    margin-top: 1vw;
  }

  .arquivos .form-arquivos .files .file{
    margin: 2px;
  }
  .arquivos .form-arquivos .files .file p {
    font-size: 2.5vw;
  }
  .arquivos .form-arquivos .files .file img{
    height: 3vw;
  }
}

.modal-success{
  position: fixed;
  align-self: center;
  width: 50%;
  height: 300px;
  z-index: 99;
  background-color: rgba(45, 54, 51, 0.9);
  top: 11vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  transition-duration: 0.3s;
}
.modal-success.invisible{
  display: none;
}
.modal-success .content.invisible{
  display: none;
}
.load.invisible{
  display: none;
}
.modal-success .content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition-duration: 0.3s;
  color: #f0f0f0;
}

.modal-success .content p{
  color: #f0f0f0;
  font-size: 1.5vw;
  transition-duration: 0.3s;
}
.modal-success .content h3{
  text-align: center;
  margin-bottom: 1vw;
}
.modal-success .content h4{
  text-align: center;
  font-size: 1.3vw;
  margin-top: 1vw;
}
.modal-success .content hr{
  border: 1px solid #ccc;
  margin-bottom: 0.4vw;
  width: 100%;
}
.modal-success .content button{
  width: 30%;
  margin-top: 2vw;
  align-self: center;
  box-shadow: none;
  transition-duration: 0.3s;
}

.erro-envio {
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.erro-envio p{
  color: #fff;
  text-align: center;
}
.erro-envio button{
  width: 30%;
  height: auto;
  margin-top: 3vw;
}

@media(max-width: 640px){
  .modal-success{
    width: 80%;
    height: 200px;
    top: 65vw;
  }
  .modal-success .content h4{
    font-size: 3.9vw;
  }
  .modal-success .content p{
    font-size: 4.2vw;
  }
  .modal-success .content hr{
    border: 0.1px solid #ccc;
  }
  .modal-success .content button{
    height: 6vw;
    font-size: 4vw;
  }
}

.termos{
  display: flex;
  align-items: center;
  margin-top: 0.5vw;
}

.termos .texto{
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.termo .texto input{
  height: auto;
  width: auto;
  margin: 0;
  padding: 0;
}
.termos .texto p{
  /* margin-left: 2px; */
  font-size: 1vw;
}
.termos .texto a{
  color: var(--cor-escura);
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bold;
  font-size: 1vw;
}

@media(max-width: 640px){
  .termos{
    margin-top: 2vw;
  }

  .termos .texto{
    margin-left: 5px;
  }
  .termo .texto input{
    height: auto;
    width: auto;
    margin: 0;
    padding: 0;
  }
  .termos .texto p{
    /* margin-left: 2px; */
    font-size: 2vw;
  }
  .termos .texto a{
    color: var(--cor-escura);
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold;
    font-size: 2vw;
  }
}

.container-simulador{
  margin-top: 15vw;
}
.container-simulador h3{
  display: none;
}
.container-simulador button{
  width: 20%;
  margin-top: 3vw;
  height: auto;
  padding: 3px 0;
}

@media(max-width: 640px){
  .container-simulador button{
    width: 40%;
  }
}

.container-parceiro{
  margin-top: 12vw;
  width: 100%;
}

.container-parceiro .explicacao h5{
  color: var(--cor-escura);
  font-size: 1.8vw;
  text-align: center;
}
.container-parceiro .explicacao p{
  color: var(--primary-color);
  font-size: 1.6vw;
  text-align: center;
}
.container-parceiro form{
  width: 100%;
}

.container-parceiro .form-parceiro{
  width: 40%;
  background: var(--primary-color);
  padding: 1vw;
  border-radius: 5px;
  margin-top: 2vw;
}

.container-parceiro .form-parceiro .container-input{
  width: 80%;
}
.container-parceiro .form-parceiro .container-input p{
  color: #f0f0f0;
  font-weight: bold;
  font-size: 1.6vw;
}
.container-parceiro .form-parceiro .container-input input{
  height: 2.5vw;
  color: var(--cor-escura);
  font-size: 1.6vw;
  padding-left: 10px;
  width: 100%;
  margin-bottom: 1vw;
  border:none;
  outline: none;
}

@media(max-width: 640px){
  .container-parceiro .explicacao h5{
    color: var(--cor-escura);
    font-size: 3.3vw;
    width: 80%;
    text-align: center;
  }
  .container-parceiro .explicacao p{
    color: var(--primary-color);
    width: 80%;
    font-size: 3.1vw;
    text-align: center;
  }
  .container-parceiro .form-parceiro{
    width: 80%;
  }
  .container-parceiro .form-parceiro .container-input{
    width: 90%;
  }
  .container-parceiro .form-parceiro .container-input p{
    font-size: 3.3vw;
  }
  .container-parceiro .form-parceiro .container-input input{
    height: 6vw;
    font-size: 3.3vw;
    padding-left: 10px;
    width: 100%;
    margin-bottom: 2vw;
  }

}

:root {
  --primary-color: #16947B;
  --cor-escura: #0B5F37;
  --title-color: #322153;
  --text-color: #fff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  min-height: 100%;
  height: auto;
}
#root{
  min-height: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
body {
  background: #fff;

  -webkit-font-smoothing: antialiased;
  color: #fff;
  color: var(--text-color);
  min-height: 100%;
  height: auto;
}

body, input, button, div, select{
  font-family: salesforce-sans, "Helvetica Neue", helvetica, arial, sans-serif;
  outline: none;
}
p{
  color: #16947B;
  color: var(--primary-color);
}

a{
  text-decoration: none;
  color: #fff;
  text-align: center;
}


.btn-padrao{
  height: 35px;
  background-color: #16947B;
  background-color: var(--primary-color);
  border: none;
  font-size: 16px;
  border-radius: 5px;
  color: #fff;
  transition-duration: 0.2s;
}
.btn-padrao:hover{
  background-color: rgb(17, 119, 99);
  cursor: pointer;
}
p.msg-error{
  color: rgb(196, 23, 23);
  width: 25vw;
  text-align: center;
  margin-top: 0.5vw;
  font-size: 1.2vw;
  height: 30px;
  margin-bottom: 0;
}
p.error{
  color: rgb(196, 23, 23);
}

.container-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input.error{
  border: 2px solid rgb(196, 23, 23);
}

button[type=submit]{
  margin-top: 20px;
  height: auto;
  width: 20%;
  padding: 5px 0;
}

.container-input.error input {
  border: 2px solid rgb(221, 52, 52);
}

.container-input.error select {
  border: 2px solid rgb(221, 52, 52);
}

.container-input.error p{
  color: rgb(233, 50, 50);
}

@media(max-width: 640px){
  .btn-padrao{
    font-size: 3vw;
  }
  p.msg-error{
    width: 60%;
    font-size: 3.5vw;
  }
  button[type=submit]{
    width: 40%;
    font-size: 3.5vw;
  }
}


.footer{
  margin-top: 50px;
  height: 18vw;
  width: 100%;
  background: linear-gradient(90deg, rgba(77,77,77,1) 0%, rgba(105,105,105,1) 100%);
  display: flex;
  justify-content: center;
}
footer.footer p{
  color: #fff;
  font-family: "Maven Pro", sans-serif;
}
footer.footer a{
  font-family: "Maven Pro", sans-serif;
}

footer.footer .flex-footer{
  display: flex;
  width:  95%;
  justify-content: space-between;
  align-items: center;
  margin-top: 3vw;
  margin-bottom: 2vw;
}
footer.footer .flex-footer .menu ul hr{
  border: 1px solid #ccc;
  margin-bottom: 0.5vw;
}
footer.footer .flex-footer .menu ul li{
  list-style: none;
  margin-bottom: 0.2vw;
}
footer.footer .flex-footer ul li a{
  font-size: 1.3vw;
}
footer.footer .flex-footer ul li a:hover{
  color: #ccc;
}
footer.footer .endereco .end{
  display: flex;
  justify-content: center;
}
footer.footer .endereco p{
  font-size: 1.3vw;
  margin-bottom: 0.5vw;
}
footer.footer .endereco img{
  height: 2vw;
  margin-right: 1vw;
}
footer.footer .endereco .pague{
  margin-bottom: 0.5vw;
}
footer.footer .endereco h3{
  color: #f0f0f0;
  font-size: 1.4vw;
  margin-bottom: 1vw;
  font-family: "Maven Pro", sans-serif;
}
footer.footer .flex-footer .contato{
  margin-left: 2vw;
}
footer.footer .flex-footer .contato h3{
  font-family: "Maven Pro", sans-serif;
}
footer.footer .flex-footer .contato p{
  font-size: 1.2vw;
  margin-left: 10px;
}
footer.footer .flex-footer .contato a{
  font-size: 1.2vw;
  margin-left: 10px;
  color: #fff;
}
footer.footer .flex-footer .contato .cont{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2.5vw;
  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
}
footer.footer .flex-footer .baixe-app{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15vw;
}
footer.footer .flex-footer .baixe-app #phone{
  height: 13vw;
  margin-top: 8px;
  margin-right: -3vw;
}
footer.footer .flex-footer .baixe-app div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 10vw;
}
footer.footer .flex-footer .baixe-app div h3 {
  color: #f0f0f0;
  font-size: 1.5vw;
}
footer.footer .flex-footer .baixe-app .loja{
  height: 3vw;
  margin-top: 15px;
}
#icon {
  height: 2.2vw;
}
@media(max-width: 640px){
  footer.footer {
    height: 35vw;
  }
  #icon{
    height: 5vw;
  }
  footer.footer .flex-footer .menu {
    margin: 0;
  }
  footer.footer .flex-footer .menu ul hr{
    margin-bottom: 0;
    border: 0.1px solid #ccc;
  }
  footer.footer .flex-footer .menu li{
    margin-bottom: 0;
  }
  footer.footer .flex-footer .menu li a{
    font-size: 3vw;
    margin-bottom: 0.3vw;
  }
  footer.footer .flex-footer .contato{
    margin-left: 5vw;
  }
  footer.footer .flex-footer .contato .cont{
    height: 6vw;
  }
  footer.footer .flex-footer .contato p{
    font-size: 3vw;
  }
  footer.footer .flex-footer .contato a{
    font-size: 3.5vw;
  }
  footer.footer .flex-footer .contato hr{
    border: 0.1px solid #ccc;
    display: none;
  }
  footer.footer .flex-footer .contato h3{
    font-size: 4vw;
    margin-bottom: 0.3vw;
  }
  footer.footer .endereco{
    display: none;
    font-size: 3vw;
  }
  footer.footer .endereco p{
    font-size: 3vw;
  }
  footer.footer .flex-footer .baixe-app{
    display: none;
  }
  footer.footer .flex-footer .baixe-app #phone{
    height: 20vw;
  }
  footer.footer .flex-footer .baixe-app .loja{
    height: 4vw;
    margin-bottom: 1vw;
  }
  footer.footer .flex-footer .baixe-app div h3{
    font-size: 2.2vw;
    text-align: center;
    margin-bottom: 1vw;
  }
}

.menu-header {
  width: 100%;
  top: 0;
}
.menu-header #background {
  width: 100%;
  margin-top: -15px;
}
.menu-header .nome-help{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  margin-top: 10;
  font-size: 3vw;
  font-weight: bold;
  margin-left: 20px;
}
.menu-header .nome-help #logo-h{
  height: 3vw;
  margin-right: 0.3vw;
}
.menu-header .nome-help .help{
  color: #0B5F37
}
.menu-header .nome-help .maney{
  color: #fff;
}

@media(max-width: 640px){
  nav{
    display: none;
  }
  .menu-header .nome-help #logo-h{
    height: 8vw;
    margin-right: 2vw;
  }
  .nome-help .help{
    font-size: 7vw;
  }
  .nome-help .maney{
    font-size: 7vw;
  }
  .menu-header .menu ul li a#pague{
    font-size: 4vw;
  }
}

nav.menu {
  position: absolute;
  top: 18px;
  right: 15px;
}
nav.menu ul {
  display: flex;
  align-items: center;
}
nav.menu ul li{
  font-size: 1.2vw;
  font-weight: bold;
  list-style: none;
  margin-right: 1.6vw;
}
nav.menu ul li a{
  color: #f0f0f0;
  text-decoration: none;
}
nav.menu ul li a:hover{
  color: #fbfbfb
}

nav.menu ul li a#pague{
  color: var(--cor-escura);
  font-size: 1.4vw;
  transition-duration: 0.2s;
}
nav.menu ul li a#pague:hover{
  color: #fff;
}

@media(max-width: 640px){
  nav.menu {
    display: none;
  }
  nav.menu ul li a#pague{
    font-size: 4vw;
  }
  .menu-toogle {
    position: absolute;
    width: 7vw;
    top: 1vw;
    right: 2vw;
    border: none;
    background: none;

  }
  .one,.two,.three {
    background-color: #fff;
    height: 3px;
    width: 100%;
    margin: 1vw auto;

    transition-duration: 0.3s;
  }
  .menu-section.on{
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    width: 40vw;
    background-color: var(--primary-color);
    z-index: 10;
    transition-duration: 0.5s;
  }
  .menu-section.on nav{
    display: block;
  }
  .menu-section.on nav ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10vw;
  }
  .menu-section.on nav ul li{
    font-size: 4vw;
    margin-top: 3vw;
    transition-duration: 0.3s;
  }.menu-toogle {
    position: absolute;
    width: 7vw;
    top: 1vw;
    right: 2vw;
    border: none;
    background: none;

  }
  .one,.two,.three {
    background-color: #fff;
    height: 3px;
    width: 100%;
    margin: 1vw auto;

    transition-duration: 0.3s;
  }
  .menu-section.on{
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    width: 40vw;
    background-color: var(--primary-color);
    z-index: 10;
    transition-duration: 0.5s;
  }
  .menu-section.on .menu-toogle .one{
    transform: rotate(45deg) translate(6px, 7px);
  }
  .menu-section.on .menu-toogle .two{
    opacity: 0;
  }
  .menu-section.on .menu-toogle .three{
    transform: rotate(-45deg) translate(2px, -4px);
  }
  .menu-section.on nav{
    display: block;
  }
  .menu-section.on nav ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10vw;
  }
  .menu-section.on nav ul li{
    font-size: 4vw;
    margin-top: 3vw;
    transition-duration: 0.3s;
  }
}

button.whats {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 1.5vw;
  z-index: 15;
  width: 100px;
  height: 55px;
  right: -3vw;
  transition: ease 0.4s;
  border-radius: 105px;
  background-color: var(--primary-color);
  border: none;
}

button.whats a{
  display: flex;
  justify-content: center;
  align-items: center;
}
button.whats img{
  height: 45px;
  margin-right: 44px;
  margin-left: 10px;
  margin-top: 2px;
}
button.whats:hover{
  cursor: pointer;
}
button.whats.open{
  width: 220px;
  right: -3vw;
  background-color: var(--primary-color);
  border-radius: 40px;
}
button.whats.open img{
  margin-right: 1px;

}
.whats.open  p{
  color: #fff;
  font-size: 16px;
  margin-bottom: 0.5vw;
  text-align: center;
  margin-right: 40px;
  transition: ease 0.8s;
}
.whats.open .text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}

@media(max-width: 640px){
  button.whats{
    background: none;
    width: 50px;
    margin-right: 10px;
    height: 60px;
    bottom: 12vw;
  }
  button.whats.open{
    background: none;
    width: 50px;
    margin-right: 15px;
  }
  button.whats.open p{
    display: none;
  }
  button.whats img{
    height: 35px;
  }
  #whats img{
    height: 8vw;
  }
  #whats{
    bottom: 5vw;
  }
}

